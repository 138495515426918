import { useState } from 'react';
import { collection, setDoc, doc, serverTimestamp, Timestamp, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const useFirebaseOrders = (user) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const saveOrder = async (extractedData) => {
    if (!extractedData) return;

    setIsSaving(true);
    try {
      const timestamp = serverTimestamp();
      const currentTimestamp = Timestamp.now();
      const priceNumber = parseFloat(extractedData.totalValue.replace(',', '.'));

      const orderData = {
        address: extractedData.address,
        createdAt: timestamp,
        customerName: extractedData.clientName,
        customerPhone: extractedData.phone,
        date: timestamp,
        orderId: extractedData.orderId,
        isDelivered: false,
        isInDelivery: false,
        isInPreparation: true,
        isPending: false,
        itemCount: extractedData.products.length,
        items: extractedData.products,
        lastStatusUpdate: timestamp,
        location: null,
        pharmacyUnitId: user?.unit || '',
        price: `R$ ${priceNumber.toFixed(2)}`,
        priceNumber: priceNumber,
        status: "Em Preparação",
        statusHistory: [
          { status: "Pendente", timestamp: currentTimestamp },
          { status: "Em Preparação", timestamp: currentTimestamp }
        ],
        updatedAt: timestamp,
        sellerId: user?.uid || '',
        sellerName: user?.displayName || 'Usuário não identificado'
      };

      const docRef = doc(collection(db, 'orders'), extractedData.orderId);
      await setDoc(docRef, orderData);
      
      return extractedData.orderId;
    } finally {
      setIsSaving(false);
    }
  };

  const cancelOrder = async (orderId) => {
    if (!orderId) return;

    setIsCancelling(true);
    try {
      const docRef = doc(collection(db, 'orders'), orderId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Pedido não encontrado');
      }

      const currentTimestamp = Timestamp.now();
      const timestamp = serverTimestamp();

      const updateData = {
        status: "Cancelado",
        isDelivered: false,
        isInDelivery: false,
        isInPreparation: false,
        isPending: false,
        lastStatusUpdate: timestamp,
        updatedAt: timestamp,
        statusHistory: [...docSnap.data().statusHistory, {
          status: "Cancelado",
          timestamp: currentTimestamp
        }]
      };

      await updateDoc(docRef, updateData);
      return orderId;
    } finally {
      setIsCancelling(false);
    }
  };

  const getOrderDetails = async (orderId) => {
    if (!orderId) return null;

    setIsFetching(true);
    try {
      const docRef = doc(collection(db, 'orders'), orderId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return null;
      }

      return docSnap.data();
    } catch (error) {
      console.error('Erro ao buscar detalhes do pedido:', error);
      throw new Error('Erro ao buscar detalhes do pedido');
    } finally {
      setIsFetching(false);
    }
  };

  return { saveOrder, cancelOrder, getOrderDetails, isSaving, isCancelling, isFetching };
};