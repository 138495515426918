// src/utils/dataExtractor.js
export const extractRelevantData = (lines, existingData = null) => {
  const linesArray = lines.split('\n');

  const findValueAfterLabel = (label, lines) => {
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes(label)) {
        const afterLabel = lines[i].split(label)[1];
        if (afterLabel?.trim()) return afterLabel.trim();
        if (i + 1 < lines.length) return lines[i + 1].trim();
      }
    }
    return '';
  };

  const findOrderId = (lines) => {
    const startKeywords = [
      'Último orçamento deste terminal',
      'Último orçamento deste ter',
      'Último orçamento des',
      'Último orçamen',
      'Último orça'
    ];

    for (let i = 0; i < lines.length; i++) {
      // Verifica se a linha atual começa com alguma das palavras-chave
      if (startKeywords.some(keyword => lines[i].trim().startsWith('Último'))) {
        const nextLine = lines[i + 1]?.trim();
        
        if (nextLine && !isNaN(nextLine)) {
          return (parseInt(nextLine) + 1).toString();
        }
      }
    }
    return '';
  };

  const findClientName = (lines) => {
    // Lista de palavras-chave para ignorar
    const invalidKeywords = [
      'convênio', 'convenio',
      'conheça', 'conheca',
      'endereço', 'endereco',
      'dúvidas', 'duvidas',
      'cpf',
      'cartão', 'cartao',
      'base de conhecimento',
      '...'
    ].map(word => word.toLowerCase());

    // Função auxiliar para validar um possível nome
    const isValidName = (name) => {
      if (!name || name.length < 2) return false;
      
      // Converte para lowercase para comparação
      const lowerName = name.toLowerCase();
      
      // Verifica se contém alguma palavra-chave inválida
      if (invalidKeywords.some(keyword => lowerName.includes(keyword))) {
        return false;
      }

      // Permitir uma variedade maior de caracteres no nome
      // Incluindo hífens, parênteses, traços e outros caracteres especiais
      // Apenas rejeitamos caracteres altamente improváveis em nomes como %, $, @, etc.
      if (/[%$@&*#<>{}[\]\/\\|+=~`^]/.test(name)) {
        return false;
      }

      // Verificação adicional para evitar linhas com números demais (prováveis códigos ou telefones)
      const digitCount = (name.match(/\d/g) || []).length;
      if (digitCount > 4 || digitCount / name.length > 0.3) {
        return false;
      }

      return true;
    };

    // Primeiro método: procura especificamente após 'Nome do Cliente'
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes('Nome do Cliente')) {
        // Extrai diretamente da mesma linha, após "Nome do Cliente"
        const sameLine = lines[i].split('Nome do Cliente')[1]?.trim();
        if (sameLine && isValidName(sameLine)) {
          return sameLine;
        }
        
        // Se não encontrou na mesma linha ou não é válido, procura nas próximas linhas
        for (let j = i + 1; j < Math.min(i + 6, lines.length); j++) {
          const possibleName = lines[j].trim();
          if (isValidName(possibleName)) {
            return possibleName;
          }
        }
      }
    }
    
    // Segundo método: tenta encontrar o nome entre '...'
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].trim() === '...') {
        if (i + 2 < lines.length && 
            lines[i + 2].trim() === '...' && 
            lines[i + 1].trim() !== '...') {
          const possibleName = lines[i + 1].trim();
          if (isValidName(possibleName)) {
            return possibleName;
          }
        }
      }
    }
    
    return '';
  };

  const findPhone = (lines) => {
    for (let i = 0; i < lines.length; i++) {
      // Procura qualquer linha que contenha um padrão próximo de telefone
      const phonePattern = /[\[\({]?\d{2}[\]\)}]?\s*\d{4,5}[-\s]?\d{4}/;
      const match = lines[i].match(phonePattern);
      
      if (match) {
        let phone = match[0];
        
        // Remove todos os caracteres especiais exceto números
        const numbers = phone.replace(/\D/g, '');
        
        // Se temos exatamente 10 ou 11 dígitos (DDD + número)
        if (numbers.length === 10 || numbers.length === 11) {
          // Reformata o número no padrão correto
          const ddd = numbers.slice(0, 2);
          const part1 = numbers.slice(2, numbers.length === 11 ? 7 : 6);
          const part2 = numbers.slice(numbers.length === 11 ? 7 : 6);
          
          return `(${ddd}) ${part1}-${part2}`;
        }
      }
    }
    return '';
  };

  const findAddressAndCEP = (lines) => {
    let address = '';
    let cep = '';

    const isCEP = (text) => {
      // More flexible CEP detection that handles variations
      return text.match(/\d{2}[\.\s]?\d{3}[-\.\s]?\d{3}/);
    };

    const isValidAddress = (text) => {
      const addressKeywords = [
        'rua',
        'quadra',
        'lote',
        'conjunto',
        'qd',
        'lt',
        'cj',
        'conj',
        'avenida',
        'av',
        'alameda',
        'travessa',
        'rod',
        'rodovia',
        'estrada',
        'praça',
        'via',
        'trecho', // Added keyword
        'chácara', // Added keyword
        'chacara', // Without accent
        'casa',    // Added keyword
        'setor',   // Added keyword
        'núcleo',  // Added keyword
        'nucleo',  // Without accent
        'área',    // Added keyword
        'area',    // Without accent
        'bloco',   // Added keyword
        'apt',     // Added keyword
        'apartamento', // Added keyword
        'qnm',     // Common in DF addresses
        'qnn',     // Common in DF addresses
        'qnp',     // Common in DF addresses
        'qnq',     // Common in DF addresses
        'shis',    // Common in DF addresses
        'shcs',    // Common in DF addresses
        'paranoa', // Specific to the example
        'brasilia' // Common city name
      ];
      
      const textLower = text.toLowerCase();
      return addressKeywords.some(keyword => textLower.includes(keyword));
    };

    // Primeiro encontra o endereço
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes('Endereço de entrega')) {
        // Look in the next line by default even without valid keywords
        const nextLineAddress = lines[i + 1]?.trim();
        if (nextLineAddress && nextLineAddress.length > 10) {
          address = nextLineAddress;
          
          // Check if there's additional address info in the following line
          // (often happens with "AO LADO DE..." type descriptions)
          const followingLine = lines[i + 3]?.trim(); // Line after "Útimos 20 itens comprados"
          if (followingLine && 
              !followingLine.includes('Filial') && 
              !followingLine.includes('Código') && 
              followingLine.length > 5) {
            address += '. ' + followingLine;
          }
          
          // Now look for the CEP
          for (let k = i; k < lines.length; k++) {
            const currentLine = lines[k].trim();
            
            if (currentLine.includes('CEP:')) {
              // First look after "CEP:" in same line
              const afterCEP = currentLine.split('CEP:')[1]?.trim();
              if (afterCEP) {
                const sameLineCEP = isCEP(afterCEP);
                if (sameLineCEP) {
                  cep = sameLineCEP[0];
                  break;
                }
              }
              
              // If not found in same line, look in next 2 lines
              for (let m = 1; m <= 3; m++) {
                if (k + m < lines.length) {
                  const nextLine = lines[k + m].trim();
                  const nextLineCEP = isCEP(nextLine);
                  if (nextLineCEP) {
                    cep = nextLineCEP[0];
                    break;
                  }
                }
              }
            }
          }
          break;
        }
      }
    }

    return { address, cep };
  };

  const extractProducts = (lines) => {
    const products = new Map();
    let startProcessing = false;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      
      // Começa a processar após essa linha
      if (line.includes('CRM/CRO/CRV')) {
        startProcessing = true;
        continue;
      }

      // Se já começamos a processar e encontramos uma linha com código de 6 dígitos
      if (startProcessing && /^\d{6}/.test(line)) {
        // Extrai todo o texto após o código como nome do produto
        const productMatch = line.match(/^\d{6}\s+(.+)$/);
        if (productMatch) {
          const productName = productMatch[1].trim();
          
          // Procura quantidade nas próximas linhas
          for (let j = i + 1; j < Math.min(i + 4, lines.length); j++) {
            const qtyLine = lines[j].trim();
            // Procura por números com até 3 casas decimais
            const qtyMatch = qtyLine.match(/^(\d+(?:,\d{1,3})?)$/);
            if (qtyMatch) {
              const quantity = parseFloat(qtyMatch[1].replace(',', '.'));
              products.set(productName, quantity);
              break;
            }
          }
        }
      }
    }

    return Array.from(products).map(([name, qty]) => `${name} (${qty}x)`);
  };

  const extractTotalValue = (lines) => {
    let values = [];
    let foundTotalGeral = false;

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes('Total Geral')) {
        foundTotalGeral = true;
        continue;
      }

      if (foundTotalGeral) {
        // Procura por valores no formato XX,XX
        const match = lines[i].match(/\d+,\d{2}/);
        if (match) {
          values.push(match[0]);
        }

        // Se encontrou 3 valores, retorna o último (Total Geral)
        if (values.length === 3) {
          return values[2];
        }
      }
    }
    return '';
  };

  // Extrair endereço e CEP
  const { address, cep } = findAddressAndCEP(linesArray);
  
  // Check if the address already contains the CEP before appending it
  const addressHasCep = cep && address.includes(cep);
  const addressHasCepLabel = address.toLowerCase().includes('cep:') || address.toLowerCase().includes('cep');
  
  let fullAddress = address;
  
  // Only append CEP if it's not already part of the address
  if (cep && !addressHasCep && !addressHasCepLabel) {
    fullAddress += ` CEP: ${cep}`;
  }
  
  // Clean up double periods, commas, and other punctuation
  fullAddress = fullAddress
    .replace(/\.{2,}/g, '.') // Replace multiple periods with a single one
    .replace(/,{2,}/g, ',')  // Replace multiple commas with a single one
    .replace(/\s+\./g, '.')  // Remove space before period
    .replace(/\s+,/g, ',')   // Remove space before comma
    .replace(/\s+:/g, ':');  // Remove space before colon

  // Extrair produtos da imagem atual
  const newProducts = extractProducts(linesArray);

  // Processar os produtos sem duplicatas
  let finalProducts = [];
  
  if (newProducts.length > 0) {
    // Se temos novos produtos, substitui completamente os antigos
    finalProducts = newProducts;
  } else if (existingData?.products) {
    // Se não encontramos novos produtos, mantemos os existentes
    finalProducts = existingData.products;
  }

  // Retorna o objeto com os dados extraídos
  return {
    clientName: findClientName(linesArray) || existingData?.clientName || '',
    phone: findPhone(linesArray) || existingData?.phone || '',
    address: fullAddress || existingData?.address || '',
    products: finalProducts,
    totalValue: extractTotalValue(linesArray) || existingData?.totalValue || '',
    orderId: findOrderId(linesArray) || existingData?.orderId || ''
  };
};