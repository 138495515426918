import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDbRHSYO4qnuGtXZYCxtYyEdNe_EMBcmZA",
    authDomain: "farmanossadelivery-76182.firebaseapp.com",
    projectId: "farmanossadelivery-76182",
    storageBucket: "farmanossadelivery-76182.firebasestorage.app",
    messagingSenderId: "670446448086",
    appId: "1:670446448086:web:d65c0cdc1e69122cb30877",
    measurementId: "G-79B2VCJ65K"
  };
  
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
